import React, {useCallback, useEffect, useState} from "react"
import {connect} from "react-redux";
import {Badge, Button, Card, Col, Row} from "react-bootstrap";
import {Link, useRouteMatch} from "react-router-dom";
import io from "socket.io-client"
import {finaliseGame, sendCommand, setClientReadyStart, setInProgress} from "../../store/actions";
import {endpoint} from "../../constants";

let socket;

const DashboardCardEmpty = () => {
  const { url } = useRouteMatch();
  return (
    <Card.Body>
      <Card.Title>Активные игры отсутствуют</Card.Title>
      <Card.Text>
        <Button variant="outline-success" as={Link} to={`${url}/game/`}>Создать новую игру</Button>
      </Card.Text>
    </Card.Body>
  );
}

const DashboardCardActiveGame = (props) => {
  const {
    gameId,
    gameTitle,
    gameLoudness,
    gameStatus,
    clientReady,
    seasonRecord,
    sendCommand,
    setClientReadyStart,
    setInProgress,
    finaliseGame
  } = props;
  const { url } = useRouteMatch();
  const [isWSEnabled, setIsWSEnabled] = useState(false);
  useEffect(() => {
    socket = io(endpoint.ENDPOINT_SOCKETS, {
      autoConnect: false,
    });
    socket.on('error', (err) => console.log('error', err));
    socket.on("connect", () => {
      console.log("Веб-сокеты подключены");
      setIsWSEnabled(true);
    });
    socket.on("disconnect", () => {
      console.log('Веб-сокеты отключены');
      setIsWSEnabled(false);
    });
    socket.on("command", ({action, payload}) => {
      switch (action) {
        case 'CLIENT_READY':
          setClientReadyStart();
          break;
        case 'FINALISE_GAME':
          finaliseGame(payload);
          alert('Игра завершена, всё оки-доки!')
          break;
      }
    });
    socket.connect();
    return () => {
      socket.disconnect();
    };
  },[setIsWSEnabled, setClientReadyStart, finaliseGame]);
  const onClick = useCallback(() => {
    setInProgress({
      gameId
    });
    sendCommand(socket, {
      action: "START_GAME",
      payload: {
        gameId,
        gameLoudness,
        seasonRecord,
        isNewRecord: seasonRecord <= gameLoudness
      }
    });
    }, [sendCommand, setInProgress, gameId, seasonRecord, gameLoudness])
  return (
    <Card.Body>
      <Card.Title>{gameTitle}</Card.Title>
      <Card.Text>
        Установленное значение шума: &nbsp;
        <Badge bg="warning" text="dark">
          {gameLoudness ? gameLoudness : '–'}
        </Badge>
      </Card.Text>
      <Button variant="outline-primary" as={Link} to={`${url}/game/${gameId}`}>Редактировать</Button>
      &nbsp; &nbsp;
      {gameLoudness && isWSEnabled && clientReady && gameStatus === 'wait_value'
        ? <Button variant="success" onClick={onClick}>Запустить</Button>
        : gameStatus === 'in_progress'
          ? <Button variant="warning" disabled>Запущена, ожидание завершения</Button>
          : <Button variant="success" disabled>Ожидание готовности клиента</Button>
      }
    </Card.Body>
  );
}

export const Dashboard = ({currentGame, clientReady, seasonRecord, sendCommand, setClientReadyStart, setInProgress, finaliseGame}) => {
  return (
    <Row>
      <Col md={6} className="mb-3">
        <Card>
          <Card.Header>Последние игры</Card.Header>
          {currentGame
            ? <DashboardCardActiveGame
              gameId={currentGame.gameId}
              gameTitle={currentGame.gameTitle}
              gameLoudness={currentGame.gameLoudness}
              gameStatus={currentGame.gameStatus}
              clientReady={clientReady}
              seasonRecord={seasonRecord}
              sendCommand={sendCommand}
              setClientReadyStart={setClientReadyStart}
              setInProgress={setInProgress}
              finaliseGame={finaliseGame}
            />
            : <DashboardCardEmpty />
          }
        </Card>
      </Col>
      <Col md={2} className="mb-3">
        <Card>
          <Card.Header>Рекорд сезона</Card.Header>
          <Card.Body>
            <Card.Title as="h1" className="text-center">{seasonRecord}</Card.Title>
          </Card.Body>
        </Card>
      </Col>
    </Row>
  );
}

const mapStateToProps = (state) => {
  return {
    currentGame: state?.games.find(game => {
      if (game.gameSeason === state.currentSeason.seasonId
          && (game.gameStatus === "wait_value"
          || game.gameStatus === "in_progress")) {
        return game;
      }
    }) || null,
    clientReady: state.clientReady,
    seasonRecord: state.games.filter(game => {
      if (game.gameSeason === state.currentSeason.seasonId)
        return game;
    }).sort((a, b) => {
      if (b.gameLoudness > a.gameLoudness) {
        return 1;
      }
      if (b.gameLoudness < a.gameLoudness) {
        return -1;
      }
      return 0;
    })[0]?.gameLoudness || null
  };
};

const mapDispatchToProps = {
  sendCommand,
  setClientReadyStart,
  setInProgress,
  finaliseGame
}

export default connect(mapStateToProps, mapDispatchToProps)(Dashboard);
