import axios from 'axios';
import {
  FINALISE_GAME, GAME_IN_PROGRESS,
  REMOVE_GAME,
  SAVE_GAME, SET_CLIENT_READY_START,
  SET_INIT_DATA, SET_VIDEO_IS_FINISHED,
} from './types';
import { endpoint } from '../constants';

export const appInit = () => {
  return (dispatch) => {
    axios
      .get(endpoint.ENDPOINT_INIT_STATE)
      .then((res) => {
        dispatch(setInitData(res.data));
      })
      .catch((err) => {
        alert('Не удалось загрузить данные.');
        console.log('Не удалось загрузить данные.', err);
      });
  };
};

export const saveGameRequest = payload => {
  return (dispatch) => {
    axios
      .post(endpoint.ENDPOINT_SAVE_GAME, payload)
      .then((res) => {
        dispatch(saveGame(res.data));
      })
      .catch((err) => {
        alert('Не удалось сохранить игру');
        console.log('Не удалось сохранить игру', err);
      });
  }
}

export const removeGameRequest = payload => {
  return (dispatch) => {
    axios
      .delete(endpoint.ENDPOINT_REMOVE_GAME, {data: payload})
      .then((res) => {
        dispatch(removeGame(res.data));
      })
      .catch((err) => {
        alert('Не удалось удалить игру');
        console.log('Не удалось удалить игру', err);
      });
  }
}

export const sendCommand = (socket, payload) => {
  return () => {
    socket.emit('command', payload)
  }
}

export const sendClientReadyStatusCommand = (socket, payload) => {
  return () => {
    socket.emit('command', payload)
  }
}

export const sendClientFinaliseGame = (socket, payload) => {
  return () => {
    socket.emit('command', payload)
  }
}

export const setInProgress = (data) => ({
  type: GAME_IN_PROGRESS,
  payload: {
    ...data
  }
})

export const finaliseGame = (data) => ({
  type: FINALISE_GAME,
  payload: {
    ...data
  }
})

export const setClientReadyStart = () => ({
  type: SET_CLIENT_READY_START,
})

export const setVideoIsFinished = () => ({
  type: SET_VIDEO_IS_FINISHED
});

export const saveGame = (data) => ({
  type: SAVE_GAME,
  payload: {
    ...data
  }
})

export const removeGame = (data) => ({
  type: REMOVE_GAME,
  payload: {
    ...data
  }
})

const setInitData = (data) => ({
  type: SET_INIT_DATA,
  payload: {
    ...data,
  },
});
