import find from "lodash/find"
import {
  FINALISE_GAME, GAME_IN_PROGRESS,
  REMOVE_GAME,
  SAVE_GAME, SET_CLIENT_READY_START,
  SET_INIT_DATA, SET_VIDEO_IS_FINISHED,
} from './types';

const initialState = {
  seasons: [],
  games: [],
  gamesBySeason: [],
  currentSeason: null,
  isFetching: true,
  clientReady: false,
  isVideoFinished: false
};

export const reducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_VIDEO_IS_FINISHED: {
      return {
        ...state,
        isVideoFinished: true
      }
    }
    case SET_CLIENT_READY_START: {
      return {
        ...state,
        clientReady: true
      }
    }
    case GAME_IN_PROGRESS: {
      const {gameId} = action.payload;
      return {
        ...state,
        games: Object.values(state.games).map((game) => {
          if (game.gameId === gameId) {
            return {
              ...game,
              gameStatus: 'in_progress',
            };
          } else {
            return game;
          }
        }),
      }
    }
    case FINALISE_GAME: {
      const {gameId} = action.payload;
      return {
        ...state,
        games: Object.values(state.games).map((game) => {
          if (game.gameId === gameId) {
            return {
              ...game,
              gameStatus: 'finished',
            };
          } else {
            return game;
          }
        }),
      }
    }
    case SET_INIT_DATA: {
      const {seasons, games, gamesBySeason} = action.payload;
      const currentSeason = find(seasons, {
        seasonIsCurrent: 1
      }) || null;
      return {
        ...state,
        seasons,
        games,
        gamesBySeason,
        currentSeason,
        isFetching: false
      }
    }
    case SAVE_GAME: {
      const {isNewGame, gameId, gameTitle, gameLoudness, gameSeason} = action.payload;
      if (isNewGame) {
        return {
          ...state,
          games: [...state.games, {
            gameId: gameId,
            gameStatus: "wait_value",
            gameTitle,
            gameLoudness,
            gameSeason
          }]
        }
      } else {
        return {
          ...state,
          games: Object.values(state.games).map((game) => {
            if (game.gameId === gameId) {
              return {
                ...game,
                gameTitle,
                gameLoudness
              };
            } else {
              return game;
            }
          }),
        }
      }
    }
    case REMOVE_GAME: {
      const {gameId} = action.payload;
      return {
        ...state,
        games: state.games.filter(game => {
          if (game.gameId !== parseInt(gameId)) {
            return game;
          }
        }),
      }
    }
    default:
      return state;
  }
};
