import React, {useEffect, useRef} from 'react';
import b from './WaitScreen.module.scss';
import classNames from 'classnames/bind';
import anime from 'animejs/lib/anime.es.js';
import logo from './assets/logo-loko.png'
import logoX2 from './assets/logo-loko@2x.png'
import loudsensorLogo from './assets/shumomer-logo.png'
import giveNoizeImg from './assets/give-noize.png'
import giveNoizeImgX2 from './assets/give-noize@2x.png'
import stripes from './assets/stripes.png'
import stripesX2 from './assets/stripes@2x.png'

let cx = classNames.bind(b);

export const WaitScreen = () => {
  const stripesRef = useRef();
  useEffect(() => {
    const stripesAnimation = anime({
      targets: stripesRef.current,
      translateX: 10,
      delay: function(el, i) { return i * 100; },
      loop: true,
      direction: 'alternate',
      easing: 'easeInOutSine'
    });
    return anime.remove(stripesAnimation);
  }, [stripesRef]);
  return (
    <>
      <div className="header">
        <div className={cx({
          logoLoko: true,
        })}>
          <img
            src={logo}
            srcSet={`${logoX2} 2x`}
            alt="ПБК Локомотив-Кубань"
          />
        </div>
        <div className={cx({
          logoShumomer: true,
        })}>
          <img src={loudsensorLogo} alt="" />
        </div>
      </div>
      <div className={cx({
        titleStart: true,
      })}>
        <img className={cx({
          letters: true,
        })}
             src={giveNoizeImg}
             srcSet={`${giveNoizeImgX2} 2x`}
             alt="Дайте шуму"
        />
        <img ref={stripesRef} className={cx({
          stripes: true,
        })}
             src={stripes}
             srcSet={`${stripesX2} 2x`}
             alt="stripes"
        />
      </div>
    </>
  )
}
