import React, {useEffect, useState} from "react"
import io from "socket.io-client"
import "./Cube.module.scss"
import {endpoint} from "../../constants";
import {WaitScreen} from "./WaitScreen";
import axios from "axios";
import {sendClientReadyStatusCommand} from "../../store/actions";
import {connect} from "react-redux";
import VideoScreen from "./VideoScreen";
import {FinalScreen} from "./FinalScreen";

let socket

const Cube = ({isVideoFinished, sendClientReadyStatusCommand}) => {
  document.body.classList.add('client');
  const [isWSEnabled, setIsWSEnabled] = useState(false);
  const [gameInfo, setGameInfo] = useState(null);
  const [video, setVideo] = useState(null);
  useEffect(() => {
    socket = io(endpoint.ENDPOINT_SOCKETS, {
      autoConnect: false,
    });
    socket.on("connect", () => {
      console.log("Веб-сокеты подключены");
      setIsWSEnabled(true);
    });
    socket.on("disconnect", () => {
      console.log('Веб-сокеты отключены');
      setIsWSEnabled(false);
    });
    socket.on("command", ({action, payload}) => {
      switch (action) {
        case 'START_GAME':
          setGameInfo(payload);
          break;
        default: return null;
      }
    });
    socket.connect();
    return () => {
      socket.disconnect()
    };
  }, [setIsWSEnabled, setGameInfo]);
  useEffect(() => {
    axios.get(endpoint.ENDPOINT_GET_VIDEO, { responseType: 'blob' }).then((res) => {
      setVideo(res.data);
      sendClientReadyStatusCommand(socket, {
        action: "CLIENT_READY",
      });
    })
  }, [setVideo, sendClientReadyStatusCommand]);

  if (!isWSEnabled) return null;
  if (!!gameInfo && !isVideoFinished) {
    return <VideoScreen video={video} gameInfo={gameInfo} />
  } else if (!!gameInfo && isVideoFinished) {
    return <FinalScreen gameInfo={gameInfo} />
  } else {
    return <WaitScreen />;
  }
}

const mapStateToProps = (state) => ({
  isVideoFinished: state.isVideoFinished
})

const mapDispatchToProps = {
  sendClientReadyStatusCommand
}

export default connect(mapStateToProps, mapDispatchToProps)(Cube);
