import React, {useEffect, useState} from "react"
import {Link, useParams, useHistory} from "react-router-dom";
import {Alert, Breadcrumb, Button, Col, Form, Row} from "react-bootstrap";
import { useFormik } from 'formik';
import find from "lodash/find"
import {connect} from "react-redux";
import {saveGameRequest} from "../../store/actions";

const GameForm = ({game, isNewGame, currentSeason, saveGame}) => {
  const [isSaved, setIsSaved] = useState(false);
  let history = useHistory();
  let initialValues = {
    gameTitle: '',
    gameLoudness: '',
    gameSeason: currentSeason.seasonId
  }
  if (!isNewGame) {
    initialValues.gameTitle = game.gameTitle;
    initialValues.gameLoudness = game.gameLoudness === null ? '' : game.gameLoudness;
    initialValues.gameSeason = game.gameSeason;
  }
  const formik = useFormik({
    initialValues,
    onSubmit: values => {
      saveGame({
        gameTitle: values.gameTitle,
        gameLoudness: values.gameLoudness === '' ? 0 : values.gameLoudness,
        gameId: !isNewGame ? game.gameId : null,
        gameSeason: initialValues.gameSeason,
        isNewGame
      })
      if (isNewGame) {
        history.push('/control/games')
      } else {
        setIsSaved(true);
      }
    },
  });
  return (
    <>
      {isSaved &&
        <Row>
          <Col md={9}>
            <Alert variant="success">
              Дружок, все изменения внесены, ты большой молодец!
            </Alert>
          </Col>
        </Row>
      }
      <Form onSubmit={formik.handleSubmit}>
        <Form.Group as={Row} className="mb-3">
          <Form.Label column md={2}>
            Название игры
          </Form.Label>
          <Col md={7}>
            <Form.Control
              id="gameTitle"
              name="gameTitle"
              value={formik.values.gameTitle}
              onChange={formik.handleChange}
              type="text"
              placeholder="Введите название игры"
            />
          </Col>
        </Form.Group>
        <Form.Group as={Row} className="mb-3">
          <Form.Label column md={2}>
            Уровень шума
          </Form.Label>
          <Col md={7}>
            <Form.Control
              id="gameLoudness"
              name="gameLoudness"
              value={formik.values.gameLoudness}
              onChange={formik.handleChange}
              type="number"
              placeholder="Укажите желаемый уровень шума"
            />
          </Col>
        </Form.Group>
        <Form.Group as={Row} className="mb-3">
          <Col sm={{ span: 10, offset: 2 }}>
            <Button type="submit">{isNewGame ? 'Создать' : 'Сохранить' }</Button>
          </Col>
        </Form.Group>
      </Form>
    </>
  );
}
export const GameCard = ({games, isFetching, currentSeason, saveGameRequest}) => {
  const {game_id} = useParams();
  const [game, setGame] = useState(null);
  const isNewGame = !game_id;
  useEffect(() => {
    const getGame = find(games, {
      gameId: parseInt(game_id)
    }) || null;
    setGame(getGame);
  }, [games, game_id]);

  if (isFetching || (!isNewGame && game === null)) {
    return null;
  }

  return (
    <>
      <Breadcrumb>
        <Breadcrumb.Item linkAs={Link} linkProps={{ to: "/control" }}>Дашборд</Breadcrumb.Item>
        <Breadcrumb.Item linkAs={Link} linkProps={{ to: "/control/games" }}>Игры</Breadcrumb.Item>
        <Breadcrumb.Item active>{game_id ? 'Редактирование игры' : 'Создание игры'}</Breadcrumb.Item>
      </Breadcrumb>
      <h1>{game_id ? 'Редактирование игры' : 'Создание игры'}</h1>
      <Row className="mt-4">
        <Col md={8}>
          <GameForm game={game} currentSeason={currentSeason} isNewGame={isNewGame} saveGame={saveGameRequest} />
        </Col>
      </Row>
    </>
  );
}

const mapStateToProps = (state) => {
  return {
    games: state.games,
    currentSeason: state.currentSeason,
    isFetching: state.isFetching
  };
};

const mapDispatchToProps = {
  saveGameRequest,
};

export default connect(mapStateToProps, mapDispatchToProps)(GameCard)
