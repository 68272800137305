import React, {useEffect, useRef} from 'react';
import anime from "animejs/lib/anime.es.js";
import b from './FinalScreen.module.scss'
import logo from './assets/logo-loko.png'
import logoX2 from './assets/logo-loko@2x.png'

export const RecordScreen = ({gameLoudness}) => {
  const bigCircleRef = useRef();
  const bigCircleInsideRef = useRef();
  const bigCircleOutsideRef = useRef();
  const resultRef = useRef();
  const labelResultRef = useRef();
  useEffect(() => {
    const bigCircleAnimation = anime({
      targets: bigCircleRef.current,
      width: 1260,
      height: 1260,
      duration: 1000,
      easing: 'easeInOutSine',
    });
    const bigCircleInsideAnimation = anime({
      targets: bigCircleInsideRef.current,
      clip: 'rect(225px, auto, 1355px, auto)',
      duration: 1000,
      easing: 'easeInOutSine',
    });
    let outsideEl = anime.timeline({
      duration: 2000,
      easing: 'easeInOutSine',
    });
    outsideEl
      .add({
        targets: bigCircleOutsideRef.current,
        clip: 'rect(546px, auto, 1255px, auto)',
      })
      .add({
        targets: resultRef.current,
        opacity: 1,
      }, 0)
      .add({
        targets: labelResultRef.current,
        opacity: 1,
      }, 0);
    outsideEl.finished.then(() => {
      anime({
        targets: bigCircleRef.current,
        direction: 'alternate',
        background: '#9b0a24',
        easing: 'linear',
        duration: 500,
        loop: true
      });
    });
    return anime.remove(bigCircleAnimation, bigCircleInsideAnimation, outsideEl);
  }, [bigCircleRef, bigCircleInsideRef, bigCircleOutsideRef, resultRef, labelResultRef]);

  return (
    <>
      <div className={b.labelNewRecordSeason}>
        <span>новый <br/> рекорд <br/> сезона</span>
      </div>
      <div className={b.logosNewRecord}>
        <img
          src={logo}
          srcSet={`${logoX2} 2x`}
          alt="ПБК Локомотив-Кубань"
        />
      </div>
      <div className={b.resultWrapper}>
        <div ref={resultRef} className={b.result}>
          <span className={b.resultValue}>{gameLoudness}</span>
          <span className={b.resultMetric}>dB</span></div>
        <div ref={labelResultRef} className={`${b.labelResult} ${b.labelResultRed}`}>РЕЗУЛЬТАТ</div>
      </div>
      <div ref={bigCircleRef} className={`${b.bigCircle} ${b.bigCircleRed}`} />
      <div ref={bigCircleInsideRef} className={b.bigCircleInside}/>
      <div ref={bigCircleOutsideRef} className={b.bigCircleOutside} />
    </>
  )
}
export const NotRecord = ({seasonRecord, gameLoudness}) => {
  const bigCircleRef = useRef();
  const bigCircleInsideRef = useRef();
  const bigCircleOutsideRef = useRef();
  const resultRef = useRef();
  const labelResultRef = useRef();
  const labelRecordSeasonRef = useRef();
  const recordSeason = useRef();
  useEffect(() => {
    const bigCircleAnimation = anime({
      targets: bigCircleRef.current,
      width: 1260,
      height: 1260,
      duration: 1000,
      easing: 'easeInOutSine',
    });
    const bigCircleInsideAnimation = anime({
      targets: bigCircleInsideRef.current,
      clip: 'rect(225px, auto, 1355px, auto)',
      duration: 1000,
      easing: 'easeInOutSine',
    });
    let outsideEl = anime.timeline({
      duration: 2000,
      easing: 'easeInOutSine',
    });
    outsideEl
      .add({
        targets: bigCircleOutsideRef.current,
        clip: 'rect(546px, auto, 1255px, auto)',
      })
      .add({
        targets: resultRef.current,
        opacity: 1,
      }, 0)
      .add({
        targets: labelResultRef.current,
        opacity: 1,
      }, 0);
    outsideEl.finished.then(() => {
      anime({
        targets: bigCircleRef.current,
        direction: 'alternate',
        background: '#005d3a',
        easing: 'linear',
        duration: 500,
        loop: true
      });
      anime({
        targets: labelRecordSeasonRef.current,
        opacity: 1,
        duration: 1000,
        easing: 'easeInOutSine',
      });
      anime({
        targets: recordSeason.current,
        opacity: 1,
        duration: 1000,
        easing: 'easeInOutSine',
        delay: 100
      });
    });
    return anime.remove(bigCircleAnimation, bigCircleInsideAnimation, outsideEl);
  }, [
    bigCircleRef,
    bigCircleInsideRef,
    bigCircleOutsideRef,
    labelResultRef,
    labelRecordSeasonRef,
    recordSeason
  ]);
  return (
    <>
      <div ref={labelRecordSeasonRef} className={b.labelRecordSeason}>рекорд сезона</div>
      <div ref={recordSeason} className={b.recordSeason}>
        <span className={b.recordSeasonLabel}>{seasonRecord}</span>
        <span className={b.recordSeasonMetric}>dB</span>
      </div>
      <div className={b.logosRecord}>
        <img
          src={logo}
          srcSet={`${logoX2} 2x`}
          alt="ПБК Локомотив-Кубань"
        />
      </div>
      <div className={b.resultWrapper}>
        <div ref={resultRef} className={b.result}>
          <span className={b.resultValue}>{gameLoudness}</span>
          <span className={b.resultMetric}>dB</span></div>
        <div ref={labelResultRef} className={`${b.labelResult} ${b.labelResultGreen}`}>РЕЗУЛЬТАТ</div>
      </div>
      <div ref={bigCircleRef} className={`${b.bigCircle} ${b.bigCircleGreen}`} />
      <div ref={bigCircleInsideRef} className={b.bigCircleInside} />
      <div ref={bigCircleOutsideRef} className={b.bigCircleOutside} />
    </>
  );
}

export const FinalScreen = ({gameInfo}) => {
  const {isNewRecord, gameLoudness, seasonRecord} = gameInfo;
  if (isNewRecord) {
    return <RecordScreen gameLoudness={gameLoudness} />;
  } else {
    return <NotRecord seasonRecord={seasonRecord} gameLoudness={gameLoudness} />
  }
}
