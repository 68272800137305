import React, {useRef, useCallback, useEffect} from 'react';
import {connect} from "react-redux";
import io from "socket.io-client"
import {sendClientFinaliseGame, setVideoIsFinished} from "../../store/actions";
import {endpoint} from "../../constants";

let socket;

const VideoScreen = ({video, gameInfo, setVideoIsFinished, sendClientFinaliseGame}) => {
  const {gameId} = gameInfo;
  const videoRef = useRef();
  useEffect(() => {
    socket = io(endpoint.ENDPOINT_SOCKETS, {
      autoConnect: false,
    });
    socket.connect();
    return () => {
      socket.disconnect();
    };
  }, []);
  useEffect(() => {
    videoRef.current.src = URL.createObjectURL(video);
  }, [video, videoRef]);
  const onEnded = useCallback(() => {
    setVideoIsFinished();
    sendClientFinaliseGame(socket, {
      action: "FINALISE_GAME",
      payload: {
        gameId
      }
    });
  }, [gameId, setVideoIsFinished, sendClientFinaliseGame])
  return (
    <div id="videoDiv">
      <div id="videoBlock">
        <video
          autoPlay="autoplay"
          muted="muted"
          ref={videoRef}
          onEnded={onEnded}
         />
      </div>
    </div>
  )
}

const mapDispatchToProps = {
  setVideoIsFinished,
  sendClientFinaliseGame
}

export default connect(null, mapDispatchToProps)(VideoScreen);
