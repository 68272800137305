import React, {useCallback} from "react"
import {Alert, Badge, Breadcrumb, Button, Spinner, Table} from "react-bootstrap";
import {Link} from "react-router-dom";
import {connect} from "react-redux";
import {removeGameRequest} from "../../store/actions";

const NoGames = () => {
  return (
    <Alert variant="secondary">
      Игры отсутствуют
    </Alert>
  );
}
const GameRow = ({id, gameTitle, gameLoudness, gameStatus, removeGame}) => {
  let statusLabel = "Не определен";
  switch (gameStatus) {
    case 'wait_value': statusLabel = 'Ожидает активации'; break;
    case 'in_progress': statusLabel = 'Запущен'; break;
    case 'finished': statusLabel = 'Завершена'; break;
  }
  const onDelete = useCallback((button) => {
    removeGame({gameId: button.currentTarget.value});
  }, [removeGame])
  return (
    <tr>
      <td>{id}</td>
      <td>
        {gameStatus === 'wait_value'
          ? <Link to={`/control/game/${id}`}>{gameTitle}</Link>
          : gameTitle
        }
      </td>
      <td className="text-center">
        <Badge bg="warning" text="dark">
          {gameLoudness ? gameLoudness : '–'}
        </Badge>
      </td>
      <td className="text-center">{statusLabel}</td>
      <td className="text-center">
        <Button onClick={onDelete} value={id} variant="danger" size="sm">
          Удалить
        </Button>
      </td>
    </tr>
  );
}
const AvailableGames = ({games, removeGame}) => {
  return (
    <Table striped bordered hover>
      <thead>
      <tr>
        <th>#</th>
        <th>Название игры</th>
        <th className="text-center">Значение шума</th>
        <th className="text-center">Статус</th>
        <th className="text-center">Действия</th>
      </tr>
      </thead>
      <tbody>
      {games.map(({ gameId, gameTitle, gameLoudness, gameStatus }) => (
        <GameRow
          key={gameId}
          id={gameId}
          gameTitle={gameTitle}
          gameLoudness={gameLoudness}
          gameStatus={gameStatus}
          removeGame={removeGame}
        />
      ))}
      </tbody>
    </Table>
  );
}

const GameTableList = ({isFetching, games, removeGame}) => {
  if (isFetching) {
    return (
      <div className="mt-4">
        <Button variant="primary" disabled>
          <Spinner
            as="span"
            animation="grow"
            size="sm"
            role="status"
            aria-hidden="true"
          />
          Жди дружище пока загрузятся данные...
        </Button>
      </div>
    )
  }
  return (
    <div className="mt-4">
      {games
      ? <AvailableGames games={games} removeGame={removeGame} />
      : <NoGames />}
    </div>
  );
}
export const GameList = ({games, currentGame, isFetching, removeGameRequest}) => {
  return (
    <>
      <Breadcrumb>
        <Breadcrumb.Item linkAs={Link} linkProps={{ to: "/control" }}>Дашборд</Breadcrumb.Item>
        <Breadcrumb.Item active>Игры</Breadcrumb.Item>
      </Breadcrumb>
      <h1>
        Список игр &nbsp;
        {!currentGame &&
          <Button variant="outline-success" as={Link} to={`/control/game/`}>Создать новую игру</Button>
        }
      </h1>
      <GameTableList isFetching={isFetching} games={games} removeGame={removeGameRequest} />
    </>
  );
}

const mapStateToProps = (state) => {
  return {
    currentGame: state.games.find((game) => {
      if (game.gameSeason === state.currentSeason.seasonId
        && game.gameStatus === "wait_value") {
        return game;
      }
    }),
    games: state.games.filter(game => {
      if (game.gameSeason === state.currentSeason.seasonId)
        return game;
    }),
    isFetching: state.isFetching
  };
};

const mapStateToDispatch = {
  removeGameRequest
}

export default connect(mapStateToProps, mapStateToDispatch)(GameList)
