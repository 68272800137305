import React, {useEffect} from "react";
import {connect} from "react-redux";
import {
  Switch,
  Route,
} from "react-router-dom";
import {appInit} from "./store/actions";
import {Admin} from "./routes/admin/Admin";
import Cube from "./routes/cube/Cube";

export const App = ({appInit}) => {
  useEffect(() => appInit(), [appInit]);
  return (
    <Switch>
      <Route path="/control">
        <Admin />
      </Route>
      <Route path="/">
        <Cube />
      </Route>
    </Switch>
  );
}

const mapDispatchToProps = {
  appInit,
};

export default connect(null, mapDispatchToProps)(App);
