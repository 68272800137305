import React from 'react';
import {
  Switch,
  Route,
  useRouteMatch, Link,
} from "react-router-dom";
import 'bootstrap/dist/css/bootstrap.min.css';
import Dashboard from "./Dashboard";
import GameList from "./GameList";
import GameCard from "./GameCard";
import {SeasonList} from "./SeasonList";
import {SeasonCard} from "./SeasonCard";
import {Col, Container, Nav, Navbar, Row} from "react-bootstrap";

export const Admin = () => {
  const { path, url } = useRouteMatch();
  return (
    <div>
      <Navbar bg="dark" variant="dark">
        <Container>
          <Navbar.Brand as={Link} to={url}>Шумомер</Navbar.Brand>
          <Nav className="me-auto">
            <Nav.Link as={Link} to={url}>Дашборд</Nav.Link>
            <Nav.Link as={Link} to={`${url}/games`}>Игры</Nav.Link>
            <Nav.Link as={Link} to={`${url}/seasons`}>Сезоны</Nav.Link>
          </Nav>
        </Container>
      </Navbar>
      <Container>
        <Row className="mt-4">
          <Col>
            <Switch>
              <Route exact path={path} children={<Dashboard />} />
              <Route exact path={`${path}/games`} children={<GameList />} />
              <Route path={`${path}/game/:game_id`} children={<GameCard />} />
              <Route path={`${path}/game/`} children={<GameCard />} />
              <Route exact path={`${path}/seasons`} children={<SeasonList />} />
              <Route path={`${path}/season/:season_id`} children={<SeasonCard />} />
              <Route path={`${path}/season/`} children={<GameCard />} />
            </Switch>
          </Col>
        </Row>
      </Container>
    </div>
  );
}
